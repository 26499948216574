import React from 'react';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import config from '../../config';

const IndexPage = () => (
  <Layout>
    <Sidebar />
    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="about"
      >
        <div className="w-100">
          <h1 className="mb-0">
            {config.firstName}
            <span className="text-primary">{config.lastName}</span>
          </h1>
          <div className="subheading mb-5">
            {config.address} · {config.phone} ·
            <a href={`mailto:${config.email}`}>{config.email}</a>
          </div>
          <p className="lead mb-5">
            I am a recent Launch Code and University of Missouri graduate,
            searching for an opportunity in web development.
          </p>
          <div className="social-icons">
            {config.socialLinks.map(social => {
              const { icon, url } = social;
              return (
                <a key={url} href={url}>
                  <i className={`fab ${icon}`}></i>
                </a>
              );
            })}
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="experience"
      >
        <div className="w-100">
          <h2 className="mb-5">Experience</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Web Developer</h3>
              <div className="subheading mb-3">Raising Sails Marketing</div>
              <p>
                Managing and creating individual WordPress sites to improve
                reliability, efficiency, and overall appeal for each customer.
                Working with different clients on ways to improve each site's
                user experience, while also updating their company's onsite
                content. Producing domain names within Google, and implementing
                APIs to fetch information as well as provide more functionality.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">December 2019 - Present</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Contract Coordinator</h3>
              <div className="subheading mb-3">Centene</div>
              <p>
                Processed credential documents between the company and different
                healthcare providers. Worked within their systems to evaluate
                and record signed signature agreements. Altered terms within the
                contracts before sending them for reviews and approvals. Ensured
                all operational permits and liability insurances are up-to-date.
                Classified practitioners’ specialties in accordance to their
                licenses and taxonomies.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">
                February 2019 - September 2019
              </span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Intern</h3>
              <div className="subheading mb-3">Data Connectors</div>
              <p>
                Communicated with the venue management coordinator to organize
                events in select cities. Formed analysis within Microsoft Excel,
                using various functions and formulas to show the pros and cons
                of each venue location. Projected international expansion values
                for conferences outside of the US. Also, compiled potential
                barriers that could come into effect if they decided to go this
                route.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">July 2018 - December 2018</span>
            </div>
          </div>

          {/* <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">Web Design Intern</h3>
              <div className="subheading mb-3">Shout! Media Productions</div>
              <p>
                Collaboratively administrate empowered markets via plug-and-play
                networks. Dynamically procrastinate B2C users after installed
                base benefits. Dramatically visualize customer directed
                convergence without revolutionary ROI.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">September 2008 - June 2010</span>
            </div>
          </div> */}
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="education"
      >
        <div className="w-100">
          <h2 className="mb-5">Education</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Launch Code</h3>
              <div className="subheading mb-3">Liftoff</div>
              <p>Project Cycle</p>
              <div></div>
              <div className="subheading mb-3">LC101</div>
              <p>Computer Programming Bootcamp</p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">
                February 2019 - September 2019
              </span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">University of Missouri - Columbia</h3>
              <div className="subheading mb-3">Bachelors of Science</div>
              <p>Business Administration - Finance</p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">August 2014 - December 2018</span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="skills"
      >
        <div className="w-100">
          <h2 className="mb-5">Skills</h2>

          <div className="subheading mb-3">
            Programming Languages &amp; Tools
          </div>
          <ul className="list-inline dev-icons">
            <li className="list-inline-item">
              <i className="fab fa-html5"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-css3-alt"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-js-square"></i>
            </li>

            <li className="list-inline-item">
              <i className="fab fa-react"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-java"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-python"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-npm"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-wordpress"></i>
            </li>
          </ul>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="interests"
      >
        <div className="w-100">
          <h2 className="mb-5">Interests</h2>
          <p>
            Apart from being a web developer, I spend a majority of my time
            outside being active. Whether it's going for a run in the park,
            kicking the soccer ball in the yard, or taking my dog for a walk
            around the neighborhood, I thoroughly enjoy a variety of outdoor and
            physical activities. As an avid jogger, I enjoy participating in
            marathons and look to continue that trend moving forward.
          </p>
          <p className="mb-5">
            When forced indoors, you can find me watching my go-to television
            shows or the NHL. My favorite team is the Saint Louis Blues and
            spend a fair share of my Winters cheering them on at the Enterprise
            Center. I also love listening to different podcasts and music, as
            well as attending concerts in an assortment of genres ranging from
            Rock to Country. Lastly, I spend a large amount of my free time
            exploring the latest technology advancements in web development.
          </p>
        </div>
      </section>

      <hr className="m-0" />

      {/* <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="projects"
      >
        <div className="w-100">
          <h2 className="mb-5">Personal Projects</h2>
          <ul className="fa-ul mb-0">
            <li>
              <i className="fa-li fa fa-trophy text-warning"></i>
              Google Analytics Certified Developer
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning"></i>1<sup>st</sup>
              Place - University of Colorado Boulder - Emerging Tech Competition
              2009
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning"></i>1<sup>st</sup>
              Place - University of Colorado Boulder - Adobe Creative Jam 2008
              (UI Design Category)
            </li>
          </ul>
        </div>
      </section> */}
    </div>
  </Layout>
);

export default IndexPage;
