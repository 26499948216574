import React, { Component, useState } from 'react';
import Scrollspy from 'react-scrollspy';
import Scroll from './Scroll';
import avatar from '../assets/images/avatar.jpg';
import config from '../../config';
import { useSpring, animated } from 'react-spring';

export default () => {
  const [open, setOpen] = useState(false);
  const navAnimation = useSpring({
    opacity: open ? 1 : 0.5,
    transform: open ? `translate3d(0, 0, 0)` : `translate3d(-100%, 0, 0)`,
    config: { friction: 20, tension: 100 },
  });

  const tabs = [
    { content: 'About', href: 'about' },
    { content: 'Experience', href: 'experience' },
    { content: 'Education', href: 'education' },
    { content: 'Skills', href: 'skills' },
    { content: 'Interests', href: 'interests' },
    // { content: 'Personal Projects', href: 'projects' },
  ];

  const handleNavOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top"
        id="sideNav"
      >
        <a className="navbar-brand" href="#page-top" id="navContent">
          <span className="d-block d-lg-none">
            {config.firstName} {config.lastName}
          </span>
          <span className="d-none d-lg-block">
            <img
              className="img-fluid img-profile rounded-circle mx-auto mb-2"
              src={avatar}
              alt=""
            />
          </span>
        </a>
        <button
          onClick={handleNavOpen}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <animated.div className="mobile-nav" style={navAnimation}>
            <ul>
              {tabs.map(tab => (
                <li key={tab.href}>
                  <a href={`#${tab.href}`}>{tab.content}</a>
                </li>
              ))}
            </ul>
          </animated.div>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <Scrollspy
            items={tabs.map(s => s.href)}
            currentClassName="active"
            offset={-300}
            className="navbar-nav"
          >
            {tabs.map((tab, i) => {
              const { href, content } = tab;
              return (
                <li className="nav-item" key={href}>
                  <Scroll type="id" element={href}>
                    <a className="nav-link" href={`#${href}`}>
                      {content}
                    </a>
                  </Scroll>
                </li>
              );
            })}
          </Scrollspy>
        </div>
      </nav>
    </>
  );
};
